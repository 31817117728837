*, :before, :after {
  box-sizing: border-box;
}

.reactor-studio {
  color: #344054;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 14px;
  font-weight: 400;
  overflow-x: auto;
}

@media print {
  .reactor-studio {
    overflow: visible;
  }
}

input.white-placeholder::placeholder {
  color: #fff;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
}

h4, h5 {
  font-weight: 500;
}

.reactor-form-label {
  font-size: 14px;
}

.reactor-form-small-label {
  color: red;
  font-size: 12px;
}

.reactor-form-section-header {
  color: #2e2a27;
  font-size: 1.25rem;
  font-weight: 500;
}

.reactor-sidebar {
  display: flex;
  position: absolute;
  transform: translateX(-100%);
}

.reactor-topbar {
  display: none;
}

.reactor-topbar-show {
  display: flex;
}

.reactor-sidebar-show {
  position: relative;
  transform: translateX(0);
}

.reactor-sidebar-menu-icon {
  display: none;
}

.reactor-document-picker-reference-mode {
  display: flex;
}

@media only screen and (width <= 800px) {
  .reactor-sidebar {
    width: 100vw;
    transition: transform .3s ease-in-out;
    position: absolute;
  }

  .reactor-sidebar-menu-icon, .reactor-topbar, .reactor-sidebar-menu-icon {
    display: flex;
  }

  .reactor-document-picker-reference-mode {
    display: none;
  }
}

.spinning {
  animation-name: spin;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

#studio-title-hero-image {
  background-position: 50%;
  background-size: cover;
  flex-direction: column;
  height: 240px;
  display: flex;
}

.studio-title-widget-body {
  flex-direction: row;
  display: flex;
}

.studio-button-content-with-icon {
  margin-left: 16px;
  display: block;
}

.studio-document-tabs-desktop {
  display: flex;
}

.studio-document-tabs-mobile {
  display: none;
}

.studio-document-tabs {
  margin-left: 24px;
  margin-right: 24px;
}

@media screen and (width <= 800px) {
  .studio-title-widget-body {
    flex-flow: wrap;
    max-width: 100%;
  }

  .studio-title-widget-avatar {
    margin-bottom: 16px;
    margin-left: -8px;
  }

  .studio-button-content-with-icon, .studio-document-tabs-desktop {
    display: none;
  }

  .studio-document-tabs-mobile {
    display: flex;
  }

  .studio-document-tabs {
    margin-left: 16px;
    margin-right: 16px;
  }
}
/*# sourceMappingURL=index.e2e2779b.css.map */
