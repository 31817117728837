*,
*:before,
*:after {
    box-sizing: border-box;
}

.reactor-studio {
    color: #344054;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
        "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji";
    font-weight: 400;
    font-size: 14px;
    overflow-x: auto;
    height: 100vh;
}

@media print {
    .reactor-studio {
        overflow: visible;
    }
}

input.white-placeholder::placeholder {
    color: white;
    opacity: 1; /* Firefox */
    font-size: 14px;
    font-weight: 400;
}

h4 {
    font-weight: 500;
}
h5 {
    font-weight: 500;
}
.reactor-form-label {
    font-size: 14px;
}

.reactor-form-small-label {
    font-size: 12px;
    color: red;
}
.reactor-form-section-header {
    font-size: 1.25rem;
    font-weight: 500;
    color: #2e2a27;
}

.reactor-sidebar {
    display: flex;
    transform: translateX(-100%);
    position: absolute;
}
.reactor-topbar {
    display: none;
}
.reactor-topbar-show {
    display: flex;
}
.reactor-sidebar-show {
    transform: translateX(0);
    position: relative;
}
.reactor-sidebar-menu-icon {
    display: none;
}
.reactor-document-picker-reference-mode {
    display: flex;
}

@media only screen and (max-width: 800px) {
    .reactor-sidebar {
        position: absolute;
        width: 100vw;

        transition: transform 0.3s ease-in-out;
    }
    .reactor-sidebar-menu-icon {
        display: flex;
    }
    .reactor-topbar {
        display: flex;
    }
    .reactor-sidebar-menu-icon {
        display: flex;
    }
    .reactor-document-picker-reference-mode {
        display: none;
    }
}

.spinning {
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

#studio-title-hero-image {
    display: flex;
    flex-direction: column;
    height: 240;
    background-size: cover;
    background-position: 50% 50%;
}

.studio-title-widget-body {
    display: flex;
    flex-direction: row;
}
.studio-button-content-with-icon {
    margin-left: 16px;
    display: block;
}
.studio-document-tabs-desktop {
    display: flex;
}
.studio-document-tabs-mobile {
    display: none;
}
.studio-document-tabs {
    margin-left: 24px;
    margin-right: 24px;
}

@media screen and (max-width: 800px) {
    .studio-title-widget-body {
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 100%;
    }
    .studio-title-widget-avatar {
        margin-left: -8px;
        margin-bottom: 16px;
    }
    .studio-button-content-with-icon {
        display: none;
    }
    .studio-document-tabs-desktop {
        display: none;
    }
    .studio-document-tabs-mobile {
        display: flex;
    }
    .studio-document-tabs {
        margin-left: 16px;
        margin-right: 16px;
    }
}
